import "./Header.css";
import mail from "./ico/envelope-solid.svg";
import home from "./ico/location-dot-solid.svg";
import phone from "./ico/phone-solid.svg";
import website from "./ico/globe-solid.svg";
import whatsapp from "./ico/whatsapp-brands-solid.svg";
import linkedin from "./ico/linkedin-brands-solid-purple.svg";

function Header({ data }) {
  const createMarkup = (html) => {
    return { __html: html.replace(/\n/g, "<br/>") };
  };

  return (
    <div className="resumee-header">
      <h1 className="name">{data.name}</h1>
      <div className="header-info">
        <p>
          <img src={home} className="icon" alt="home icon"></img>
          <span className="titles">Home:</span> {data.home}
        </p>
        <p>
          <img src={mail} className="icon" alt="mail icon"></img>
          <span className="titles">Email:</span> {data.email}
        </p>
        <p>
          <img src={phone} className="icon" alt="phone icon"></img>
          <span className="titles">Phone:</span> {data.phone}
        </p>
        <p>
          <img src={website} className="icon" alt="website icon"></img>
          <span className="titles">Website:</span> {data.website}
        </p>
        <p>
          <img src={whatsapp} className="icon" alt="whatsapp icon"></img>
          <span className="titles">WhatsApp Messenger:</span> {data.whatsapp}
        </p>
        <p>
          <img src={linkedin} className="icon" alt="linkedin icon"></img>
          <span className="titles">LinkedIn:</span> {data.linkedin}
        </p>
        <div>
          <p>
            <span className="titles">Gender:</span> {data.gender}
          </p>
          <p>
            <span className="titles">Date of birth:</span> {data.date_of_birth}
          </p>
          <p>
            <span className="titles">Nationality:</span> {data.nationality}
          </p>
        </div>

        <h2 className="about-me-title sub-title">ABOUT ME</h2>
        <p
          className="about-me-desc"
          dangerouslySetInnerHTML={createMarkup(data.about_me)}
        ></p>
      </div>
    </div>
  );
}

export default Header;
