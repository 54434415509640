import linkedin from "./ico/linkedin-brands-solid.svg";
import facebook from "./ico/square-facebook-brands-solid.svg";
import github from "./ico/square-github-brands-solid.svg";
import "./Social.css";

function Social({ icon, onClick }) {
  return (
    <li>
      <button onClick={onClick}>
        <img src={icon} alt="social icon" className="social-ico" />
      </button>
    </li>
  );
}

export default Social;
