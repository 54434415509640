import "./WorkExperience.css";

function WorkExperience({ job }) {
  const createMarkup = (html) => {
    return { __html: html.replace(/\n/g, "<br/>") };
  };

  return (
    <div className="work-experience">
      <h3 className="position">
        {job.position}{" "}
        <span className="date">
          [ {job.from} {job.to ? `- ${job.to}` : ""} ]
        </span>
      </h3>

      <h3 className="company">{job.company}</h3>
      <p className="location">
        <span className="city-country">City:</span> {job.city} |
        <span className="city-country"> Country:</span> {job.country}
      </p>
      <p dangerouslySetInnerHTML={createMarkup(job.description)} />
      <h4 className="technologies-title">Technologies</h4>
      <ul className="technologies">
        {job.technologies.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>
    </div>
  );
}

export default WorkExperience;
