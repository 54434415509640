import "./App.css";
import { useState } from "react";
import Submenu from "./Components/Submenu/Submenu";
import topics from "./data";
import img from "./Resources/Poza.png";
import Social from "./Components/Social/Social";
import linkedin from "./Components/Social/ico/linkedin-brands-solid.svg";
import facebook from "./Components/Social/ico/square-facebook-brands-solid.svg";
import github from "./Components/Social/ico/square-github-brands-solid.svg";
import Resumee from "./Components/Resumee/Resumee";
import WhatsAppButton from "./Components/WhatsAppButton/WhatsAppButton";


function App() {
  const [selectedTopic, setSelectedTopic] = useState("about-me");

  function handleClick(selectedButton) {
    setSelectedTopic(selectedButton);
    console.log(selectedButton);
  }

  const handlePlatform = (platform) => {
    if (platform === "LinkedIn") {
      window.open(
        "https://www.linkedin.com/in/alexandru-suciu-04a772b5/",
        "_blank"
      );
    } else if (platform === "Facebook") {
      window.open("https://www.facebook.com/Baies.Victor/", "_blank");
    } else if (platform === "GitHub") {
      window.open("https://github.com/AlexSuciu1989/", "_blank");
    }
  };

  return (
    <div className="App">
      <div className="greeting-container">
        <div className="img">
          <img src={img} className="mypicture"></img>
        </div>
        <h1>Hi, I'm Alex.</h1>
        <p>
          Front End Developer who focuses on writing clean, elegant and
          efficient code.
        </p>
        {/* <button>Let's get in touch</button> */}
        <WhatsAppButton phoneNumber="+40741062732" message="" />
      </div>
      <div className="content-container">
        <div className="content-header">
          <ul className="menu-container">
            <Submenu onClick={() => handleClick("about-me")}>About Me</Submenu>
            <Submenu onClick={() => handleClick("my-projects")}>
              My Projects
            </Submenu>
            <Submenu onClick={() => handleClick("testimonials")}>
              Testimonials
            </Submenu>
          </ul>
          <ul className="social-container">
            <Social
              icon={linkedin}
              onClick={() => handlePlatform("LinkedIn")}
            />
            <Social
              icon={facebook}
              onClick={() => handlePlatform("Facebook")}
            />
            <Social icon={github} onClick={() => handlePlatform("GitHub")} />
          </ul>
          <Resumee />
        </div>
        <div className="content">
          {!selectedTopic ? (
            <p>Please select a topic</p>
          ) : (
            <div>
              <h1>{topics[selectedTopic].title}</h1>
              <div>{topics[selectedTopic].description}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
