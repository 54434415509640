import React from 'react';
import whatsappLogo from './WhatsAppButtonGreenSmall.png'

const WhatsAppButton = ({ phoneNumber, message }) => {
  const formattedMessage = message ? encodeURIComponent(message) : '';
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${formattedMessage}`;

  return (
    <a aria-label="Chat on WhatsApp" href={whatsappLink} target="_blank" rel="noopener noreferrer">
      <img alt="Chat on WhatsApp" src={whatsappLogo} style={{ width: '250px' }} />
    </a>
  );
};

export default WhatsAppButton;
