import "./Projects.css";

function Projects({ project }) {
  const createMarkup = (html) => {
    return { __html: html.replace(/\n/g, "<br/>") };
  };

  return (
    <div>
      <h3 className="projects-title">
        {project.title}{" "}
        <span className="date">
          [{" "}
          {project.to === project.from || project.to === ""
            ? `${project.from}`
            : `${project.to} - ${project.from}`}{" "}
          ]
        </span>
      </h3>
      <p dangerouslySetInnerHTML={createMarkup(project.description)} />
    </div>
  );
}

export default Projects;
