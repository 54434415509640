import React, { useState, useRef } from "react";
import resIco from "./ico/file-lines-regular.svg";
import pdfIcon from "./ico/file-pdf-solid.svg";
import "./Resumee.css";
import data from "./data";
import Header from "./Header";
import WorkExperience from "./WorkExperience";
import EducationAndTraining from "./EducationAndTraining";
import LanguageSkills from "./LanguageSkills";
import DigitalSkills from "./DigitalSkills";
import Projects from "./Projects";
import HonoursAndAwards from "./HonoursAndAwards";
import DrivingLicence from "./DrivingLicence";

function Resumee() {
  const [resumeeVisibility, setResumeeVisibility] = useState("hide-resumee");
  const resumeeRef = useRef();

  const printResume = () => {
    window.print();
  };

  return (
    <div className="resumee-container">
      <div>
        <img
          src={resIco}
          className="resumee-ico"
          title="Resumee"
          alt="Resumee Icon"
          onClick={() => setResumeeVisibility("")}
        />
      </div>
      <div className={`resumee-popup ${resumeeVisibility}`}>
        <button
          className="close-resumee"
          onClick={() => setResumeeVisibility("hide-resumee")}
          aria-label="Close Resume"
        >
          X
        </button>
        <img
          src={pdfIcon}
          onClick={printResume}
          className="download-pdf"
          alt="Print as PDF"
          title="Print as PDF"
        />
        <div className="resumee" ref={resumeeRef}>
          <Header data={data} />
          <h2 className="sub-title">WORK EXPERIENCE</h2>
          {data.work_experience.map((job, index) => (
            <WorkExperience key={index} job={job} />
          ))}
          <h2 className="sub-title">EDUCATION AND TRAINING</h2>
          {data.education_and_training.map((edu, index) => (
            <EducationAndTraining key={index} edu={edu} />
          ))}
          <h2 className="sub-title">LANGUAGE SKILLS</h2>
          {data.language_skills.map((language, index) => (
            <LanguageSkills key={index} language={language} />
          ))}
          <h2 className="sub-title">DIGITAL SKILLS</h2>
          <DigitalSkills skills={data.digital_skills} />
          <h2 className="sub-title">PROJECTS</h2>
          {data.projects.map((data, index) => (
            <Projects key={index} project={data} />
          ))}
          <h2 className="sub-title">HONOURS AND AWARDS</h2>
          {data.honours_and_awards.map((award, index) => (
            <HonoursAndAwards key={index} honor={award} />
          ))}
          <h2 className="sub-title">DRIVING LICENCE</h2>
          {data.driving_license.map((licence, index) => (
            <DrivingLicence key={index} licence={licence} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Resumee;
