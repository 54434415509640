import "./Projects.css";
import Project from "./Project";
import { useState } from "react";
import projectDescription from "./data";

function Projects() {
  const [selectedTopic, setSeletecTopic] = useState();

  function handleClick(selectedTopic) {
    setSeletecTopic(selectedTopic);
  }

  const createMarkup = (html) => {
    return { __html: html.replace(/\n/g, "<br/>") };
  };

  return (
    <div>
      <div className="Projects">
        <Project onClick={() => handleClick("homebuddy")} className={selectedTopic === "homebuddy" ? "selected" : ""}>Homebuddy</Project>
        <Project onClick={() => handleClick("logiscool")} className={selectedTopic === "logiscool" ? "selected" : ""}>Logiscool</Project>
        <Project onClick={() => handleClick("poke-dex")} className={selectedTopic === "poke-dex" ? "selected" : ""}>Poke Dex</Project>
        <Project onClick={() => handleClick("add-resumee")} className={selectedTopic === "add-resumee" ? "selected" : ""}>Resumee Builder</Project>
      </div>
      {!selectedTopic ? (
        <p>Please select a Project!</p>
      ) : (
        <div>
            <p className="project-description" dangerouslySetInnerHTML={createMarkup(projectDescription[selectedTopic].description)} />
            <a
              className="project-url"
              href={projectDescription[selectedTopic].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to project
            </a>
         
        </div>
      )}
    </div>
  );
}

export default Projects;
