const data = {
  name: "Alexandru Suciu",
  home: "Corneliu Coposu No. 120D, Ap.12, 450018, Zalau, Romania",
  email: "v.alex.suciu@gmail.com",
  phone: "(+40) 0741062732",
  website: "https://alex-suciu.homebuddy.ro",
  whatsapp: " 0741062732",
  linkedin: "https://www.linkedin.com/in/alexandru-suciu-04a772b5/",
  gender: "Male",
  date_of_birth: "12/08/1989",
  nationality: "Romanian",
  about_me: `I first started the front end development journey with Scoala Informala de IT, attending the first course of "Intro in IT", continuing with the "Web development JavaScript" program at the same school. Regarding my skills, I continuously improve them using the Udemy platform, where I completed several programs such as: Git & GitHub, Bootstrap, React, Typescript, API/RESTful API and more tools. As for my experience, I work on the website that I developed, named HomeBuddy, which was designed as a scalable Web Application for family to-do lists and other activities.`,
  work_experience: [
    {
      position: "Frontend Developer",
      company: "Logiscool Romania",
      from: "01/04/2024",
      to: "Current",
      city: "Remote",
      country: "Romania",
      description: `• Developing a multi-user application for managing teacher-parent interaction, including features for class rescheduling, feedback submission, and school news distribution.
          • Creation of dynamic calendar for classes.
          • Creation of responsive and visually appealing user interfaces.
          • Collaborating closely with cross-functional teams using Agile methodologies to ensure timely delivery of features and enhancements.
          • Enhancing UI functionality and aesthetics.
          • Maintaining clean and organized codebase
          • Facilitating efficient collaboration and code reviews within the development team.`,
      technologies: [
        "HTML",
        "CSS",
        "JavaScript",
        "TypeScript",
        "SCSS",
        "React",
        "Bootstrap",
        "Git",
        "GitHub",
        "Docker",
      ],
    },
    {
      position: "Founder & Developer",
      company: "HomeBuddy",
      from: "01/11/2023",
      to: "Current",
      city: "Remote",
      country: "Romania",
      description: `• HomeBuddy is a work in progress project, designed to be the perfect tool to cover all tasks that can be managed online by every member of the family.
        • The main features of the application are: Register/Login, Account Management, Recipe definition, Meal planning, Budget tracking, Task management and To Do's.
        • My role as founder and developer include: UI/UX development; Front-end development; Back-end development; Database development and management; Domain administration; Testing`,
      technologies: [
        "HTML",
        "CSS",
        "JavaScript",
        "TypeScript",
        "PhP",
        "React",
        "MySQL",
        "MariaDB",
        "Bootstrap",
        "AntDesign",
        "SCSS",
        "Git",
        "GitHub",
      ],
    },
    {
      position:
        "Quality Specialist and Application Development and Maintenance",
      company: "Michelin",
      from: "01/10/2023",
      to: "Current",
      city: "Zalau",
      country: "Romania",
      description: `Application Development and Maintenance: 
            • Creation of applications for the Quality Management System;
            • Testing, bug identification and treatment;
            • Data analysis and visualization.

          Quality:
            • Project Management for Factory Modifications;
            • Team Coordination for 5M Risk analysis,
            • Action plan definition and follow-up;
            • Participation in the internal audits for the QMS system based on ISO and IATF standards;
            • Revision and follow-up of FMEA;
            • Process capability analysis;
            • Process improvement identification, coordination and follow-up.`,
      technologies: [
        "HTML",
        "CSS",
        "JavaScript",
        "Power Apps",
        "Power Pages",
        "Power Automate",
        "Microsoft VBA",
        "SharePoint",
        "SQL",
        "Oracle",
        "Power Bi",
        "DAX",
        "Microsoft Office",
        "FMEA",
        "Kanban",
        "Ishikawa",
        "Microsoft Projects",
        "Lean Manufacturing",
        "6Sigma",
        "Internal Audit",
        "Cp/Cpk",
      ],
    },
    {
      position: "Quality Assurance and Continuous Improvement Manager",
      company: "Grovinvest",
      from: "01/09/2022",
      to: "01/10/2023",
      city: "Jibou",
      country: "Romania",
      description: `• Manage ISO 9001 / ISO 14001 / ISO 13485;
          • Claim Management and solving;
          • Production Check and Quality analysis;
          • Continuous improvement Projects using Lean 6sigma;
          • Environment management in accordance to ISO;
          • KPI implementation and data analysis;
          • 5S Projects and follow-up;
          • Usage of 3D scanning technologies;
          • Procedures;
          • Quality and Environment Trainings to shop floor;
          • SharePoint administration.`,
      technologies: [
        "SharePoint",
        "Power Apps",
        "Internal Audit",
        "External Audit",
        "6Sigma",
        "Lean Manufacturing",
        "FMEA",
        "Cp/Cpk",
        "Microsoft Office",
        "Power Bi",
        "5S",
        "3D Scanning",
      ],
    },
    {
      position: "Quality Manager",
      company: "Michelin",
      from: "01/02/2022",
      to: "01/09/2022",
      city: "Zalau",
      country: "Romania",
      description: `• Quality Engineers team coordination (e.g. tasks, objectives, problem solving);
            • Participation on internal and external audits;
            • KPI analysis and reporting;
            • Problem solving;
            • Machine follow-up and improvement;
            • Machine parameters definition and setup;
            • Machine troubleshooting for quality improvement;
            • Technical instructions creation and validation;
            • Support the productions team in order to improve the quality of the products and root cause analysis completed with actions for the quality problems;
            • Feasibility analysis.`,
      technologies: [
        "Internal Audit",
        "External Audit",
        "6Sigma",
        "Lean Manufacturing",
        "Problem Solving",
        "SharePoint",
        "Power Bi",
        "Microsoft Office",
        "Cp/Cpk",
        "Root cause analysis",
        "Ishikawa",
      ],
    },
    {
      position: "Process Improvement Specialist",
      company: "Michelin",
      from: "01/02/2021",
      to: "01/02/2022",
      city: "Zalau",
      country: "Romania",
      description: `• Continuous improvement using 6sigma Lean (DMAIC) methodology;
            • Time analysis using Basic MOST;
            • Activity analysis using Continuous observation and Chrono;
            • Lead time definition;
            • Cycle time calculation and analysis;
            • Optimization of the process;
            • KPI analysis and reporting;
            • Investment calculations.`,
      technologies: [
        "6Sigma",
        "Lean Manufacturing",
        "DMAIC",
        "Basic MOST",
        "Chrono",
        "Continuous Observation",
        "Gemba walk",
        "SharePoint",
        "Power Bi",
        "Microsoft Office",
        "Microsoft VBA",
        "Cp/Cpk",
      ],
    },
    {
      position: "Process Technologist",
      company: "Tenaris Silcotub",
      from: "01/09/2018",
      to: "01/02/2021",
      city: "Zalau",
      country: "Romania",
      description: `• Process failure analysis;
          • Product failure analysis;
          • Machine parameter definition for obtaining conform products;
          • Client claim management and root cause analysis;
          • Participation in Internal Audits and External Audits;
          • New machine installation ramp-up and process definition;
          • Data analysis using 6sigma, database requests and data manipulation for reporting;
          • Share-point administration and configuration for the department site;
          • Creation of automatic calculation tool for tooling definition based on input variables.`,
      technologies: [
        "6Sigma",
        "Problem Solving",
        "Root cause analysis",
        "5Why",
        "Ishikawa",
        "Internal Audit",
        "External Audit",
        "Cp/Cpk",
        "FMEA",
        "SQL",
        "SharePoint",
        "Power Bi",
        "Microsoft Office",
      ],
    },
    {
      position: "Laboratory Technician ",
      company: "Tenaris Silcotub",
      from: "01/08/2015",
      to: "01/08/2015",
      city: "Zalau",
      country: "Romania",
      description: `• Failure analysis on products by means of Macroscopic and Microscopic analysis;
          • Material characterization by means of optical microscopy, mechanical properties, chemical analysis;
          • Simulated heat treatments (e.g. tempering curves);
          • Specimen preparation/laboratory tests according to applicable standards and/or internal documents;
          • Assist in the resolution of detected problems;
          • Verifies the application and development of safety standards.`,
      technologies: [
        "Optical Microscopy",
        "Scanning Electron Microscopy",
        "Tempering Curves",
        "Cp/Cpk",
        "SharePoint",
        "Microsoft VBA",
        "Microsoft Office",
        "Power Bi",
        "Root Cause analysis",
        "Failure analysis",
      ],
    },
    {
      position: "Mill product & Metallurgy Engineer",
      company: "Tenaris Silcotub",
      from: "01/08/2013",
      to: "01/07/2015",
      city: "Zalau",
      country: "Romania",
      description: `• Failure analysis on products by means of Macroscopic and Microscopic analysis;
          • Material characterization by means of optical microscopy, mechanical properties, chemical analysis;
          • Simulated heat treatments (e.g. tempering curves); - Statistical analysis and construction of excel databases; - Norms administration;
          • Material reallocation from order to order;
          • Management of product samples for clients;
          • Technical queries (technical feasibility analysis for OCTG products)`,
      technologies: [
        "Optical Microscopy",
        "Root cause analysis",
        "Failure analysis",
        "Internal Audits",
        "Cp/Cpk",
        "Risk Analysis",
        "Microsoft Office",
        "SharePoint",
        "Microsoft VBA",
      ],
    },
  ],
  education_and_training: [
    {
      title: "Understanding APIs and RESTful APIs Crash Course ",
      from: "2024",
      to: "2024",
      school_or_trainer: "Kalob Taulien",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "Clean Code",
      from: "2024",
      to: "2024",
      school_or_trainer: "Maximilian Schwarzmuller",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "Agile Fundamentals: Including Scrum & Kanban",
      from: "2024",
      to: "2024",
      school_or_trainer:
        "The BA Guide | Jeremy Aschenbrenner, Vivek & Pabitra Khattri",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "Understanding TypeScript",
      from: "2024",
      to: "2024",
      school_or_trainer: "Maximilian Schwartzmuller",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "React the complete Guide 2024 (incl React Router & Redux)",
      from: "2024",
      to: "2024",
      school_or_trainer: "Maximilian Schwartzmuller",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "APIs in PHP: from Basic to Advanced",
      from: "2024",
      to: "2024",
      school_or_trainer: "Dave Hollingworth",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "The Bootstrap 4 Bootcamp",
      from: "2024",
      to: "2024",
      school_or_trainer: "Colt Steele",
      city: "",
      country: "",
      website: `www.udemy.com`,
      description: "",
    },
    {
      title: "Certificate of Participation, Intro IT",
      from: "2023",
      to: "2023",
      school_or_trainer: "Scoala informala de IT",
      city: "",
      country: "Romania",
      website: `https://scoalainformala.ro/`,
      description: "",
    },
    {
      title: "Web development - JavaScript",
      from: "2023",
      to: "2024",
      school_or_trainer: "Scoala informala de IT",
      city: "",
      country: "Romania",
      website: `https://scoalainformala.ro/`,
      description: "",
    },
    {
      title: "ISO 13485:2016, ISO 19011:2018 SMI Internal Auditor",
      from: "2022",
      to: "2022",
      school_or_trainer: "TUV Austria",
      city: "",
      country: "",
      website: `https://www.tuv-austria.ro/`,
      description: "",
    },
    {
      title: "ISO 9001:2015, ISO 14001:2015, ISO 45001:2018 Internal Auditor",
      from: "2019",
      to: "2019",
      school_or_trainer: "Lloyds Register Romania",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title: "Personal Effectiveness",
      from: "2016",
      to: "2016",
      school_or_trainer: "Trend Consult",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title: "Effective Presentations",
      from: "2015",
      to: "2015",
      school_or_trainer: "Aims HR Training",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title: "Conflict Management",
      from: "2014",
      to: "2014",
      school_or_trainer: "Trend Consult",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title: "Managing Resources",
      from: "2014",
      to: "2014",
      school_or_trainer: "Dale Carnegie Training",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title: "Excel Advanced",
      from: "2014",
      to: "2014",
      school_or_trainer: "Axioma Solutions",
      city: "",
      country: "",
      website: ``,
      description: "",
    },
    {
      title:
        "Master degree, Advanced material processing engineering and management",
      from: "2013",
      to: "2015",
      school_or_trainer: "Technical University of Cluj-Napoca",
      city: "Cluj-Napoca",
      country: "Romania",
      website: ``,
      description: "",
    },
    {
      title: "Engineer degree, Material science",
      from: "2009",
      to: "2013",
      school_or_trainer: "Technical University of Cluj-Napoca",
      city: "Cluj-Napoca",
      country: "Romania",
      website: ``,
      description: "",
    },
    {
      title: " Technician, Data calculation",
      from: "2005",
      to: "2009",
      school_or_trainer: "Alesandru Papiu Ilarian Technical College",
      city: "Zalau",
      country: "Romania",
      website: ``,
      description: "",
    },
  ],
  language_skills: [
    {
      language_type: "Mother tongue(s)",
      language: "Romanian",
      listening: "",
      reading: "",
      writing: "",
      spoken_production: "",
      spoken_interaction: "",
    },
    {
      language_type: "Other language(s)",
      language: "English",
      listening: "C2",
      reading: "C2",
      writing: "C1",
      spoken_production: "C2",
      spoken_interaction: "C2",
    },
    {
      language_type: "Other language(s)",
      language: "French",
      listening: "A2",
      reading: "A2",
      writing: "A2",
      spoken_production: "A2",
      spoken_interaction: "A2",
    },
  ],
  digital_skills: [
    "HTML",
    "JavaScript",
    "React",
    "CSS",
    "SCSS",
    "SQL",
    "GitHub",
    "PhP",
    "Bootstrap",
    "Git",
    "Power Bi",
    "Power Apps",
    "Power Pages",
    "Microsoft VBA",
    "Microsoft Office",
    "Docker",
    "MariaDB",
    "Oracle",
  ],
  projects: [
    {
      title: "HomeBuddy",
      from: "2023",
      to: "Current",
      description: `Homebuddy is a self built website used as a problem solving tool designed to help families with their to-do lists, meal planning, budget tracking and so on.
          
      What drives the project:
            This website started off as a project I was working on for the IT courses I took. I created Homebuddy from top to bottom, including the front-end, back-end and databases (SQL), integrating valuable tools and techniques.
            Homebuddy is still a work in progress, as I continually update its functions and design so as to make it the perfect tool to cover all tasks that can be managed online by every
            member of the family.
          Link: https://homebuddy.ro/`,
    },
  ],
  honours_and_awards: [
    {
      title: "Diploma on SIMTECH 3rd Place",
      issuer: "Universitatea Tehnica din Cluj-Napoca",
      date: "01/05/2023",
      description:
        "Characterization of amorphous materials of Ni61Nb33Zr6 and Ni63Nb33Zr4 used for Hydrogen separation membranes.",
    },
  ],
  driving_license: [
    {
      vehicle_type: "Motorbikes",
      license: "AM",
    },
    {
      vehicle_type: "Cars",
      license: "B",
    },
  ],
};

export default data;
