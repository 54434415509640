import Projects from "./Components/Projects/Projects";

const topics = {
    'about-me': {
      title: 'About Me',
      description:   `I first started the front end development journey with "Scoala Informala de IT", attending
      the first course of "Intro in IT", continuing with the "Web development JavaScript"
      program at the same school. Regarding my skills, I continuously improve them using the
      Udemy platform, where I completed several programs such as: Git & GitHub, Bootstrap,
      React, Typescript, API/RESTful API and more tools. Curently I am working as application developer at Michelin.`,
    },
    'my-projects': {
      title: 'My Projects',
      description: <Projects />,
    },
    'testimonials': {
      title: 'Testimonials',
      description: 'Testimonials description',
    },
  };

  export default topics;

