const projectDescription = {
  homebuddy: {
    url: "https://homebuddy.ro/",
    description: `This website started off as a project I was working on for the IT courses I took. 
        I created Homebuddy from top to bottom, including the front-end, back-end and databases (SQL), integrating valuable tools and techniques.
        The website features a registration/login system, account management, creation of food recipes together with ingredients and definition of weekly menu, expenses tracking system, events and to do system together with dynamic calendar.
        
        <b><u>Main features:</u></b>
        <ul>
            <li>Registration</li>
            <li>Login</li>
            <li>Family members definition and customization</li>
            <li>Account information update & Change password</li>
            <li>Website tracking for each user activity</li>
            <li>Introduction of new recipes together with ingredients</li>
            <li>Selection of weekly menu based on your recipes. It can be set in advance as well</li>
            <li>Calculation for each week the total ingredients needed together with individual recipe ingredients</li>
            <li>Budget tracking, introduction and displaying of the data inserted</li>
            <li>Events and to do with or without recurrence, asigned user and event type</li>
            <li>Dynamic Calendar to display the events, modify, complete or delete the events</li>
        </ul>
        <b><u>Technologies</u></b>
        HTML; CSS; JavaScript; React; SCSS; TypeScript; Git & GitHub; PhP;

        `,
  },
  logiscool: {
    url: "https://alex-suciu.homebuddy.ro/logiscool/login.html",
    description: `Logiscool is a IT scool for kids. The application is a two way channel between parents and teachers, where parents can track their children school attendance, reschedule classes, give and recieve feedback, see upcoming courses trough dynamic calendar and check the school news. 
    Here I contributed as sole Front-end Developer using a modern aproach.
    <b><u>Main features:</u></b>
    <ul>
      <li>Registration</li>
      <li>Login</li>
      <li>Creation of Cookies with the school and students that are used later to fetch filtered data</li>
      <li>Dynamic calendar with courses scheduled for each student</li>
      <li>Customized news fetched for each login user</li>
      <li>Class attendance status for each selected student</li>
      <li>Posibility to reschedule the courses where the student was absent</li>
      <li>The available courses when he can reschedule are fetched from the API and created as individual cards based on the category (online/class)</li>
      <li>If none of the courses match the user requirement, he can propose a interval based on school free interval, available teachers and classrooms</li>
    </ul>
    <b><u>Technologies</u><b>
    HTML; CSS; JavaScript; Bootstrap; SCSS; Git & GitHub; Docker; Python;
    `,
  },
  "poke-dex": {
    url: "https://alex-suciu.homebuddy.ro/pokedex/index.html",
    description: `This is a simple game I created during my courses of React.js. The game consists in creating a PokeGame which is made from two PokeDeck Components. 
    The PokeDeck component is made also of individual PokeCards Components. The PokeDecks for the game are created by splitting evenly and randomly a bigger deck that is held into an array.
    The application calculates the Exp of each deck and decides who has the winning or losing hand, and then renders it.
    <b><u>Main features:</u></b>
    <ul>
      <li>Deck builder</li>
      <li>Deck splitting randomly and equal number of cards </li>
      <li>Calculation of each deck total experience</li>
      <li>Declaration of Winner/Loser together with custom CSS</li>
    </ul>
    <b></u>Technologies</u></b>
    HTML; CSS; JavaScript; React; Git & Github
    `,
  },
  "add-resumee": {
    url: "https://alex-suciu.homebuddy.ro/resumee-builder/index.html",
    description:`Resumee Builder is a application to create, update and download you Resumee. The application was built using React.js.
    <b><u>Main features:</u></b>
    <ul>
      <li>Registration</li>
      <li>Login</li>
      <li>Creation of Resumee, dinamically adding fields for each category</li>
      <li>Update the Resumee</li>
      <li>Delete sections</li>
      <li>Template choosing</li>
      <li>Downloading the Resumee</li>
    </ul>
    <b><u>Technologies</u><b>
    HTML; CSS; JavaScript; React; Axios; PhP; SQL;
    `,
  }
};

export default projectDescription;
